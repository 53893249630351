<template>
  <div
    class="flex items-center gap-2.5"
    data-el="ui-user-role-select"
  >
    <span class="text-sm text-grey">
      {{ props.label }}
    </span>

    <UiSelect
      v-model:selected="selectedRoleValue"
      small
      :options="props.options"
      :searchable="false"
      class="select-none"
      variant="role"
      :icon="{
        name: 'arrow-down',
        width: 14,
        height: 9,
      }"
      @update:selected="(option) => optionChanged(option)"
    />
  </div>
</template>

<script setup lang="ts">
import UiSelect from '../UiSelect/UiSelect.vue'
import type { UserRoleOption } from './UiUserRoleSelect.types.ts'

const props = defineProps<{
  cookie?: string
  label?: string
  selectedRole?: UserRoleOption
  options: UserRoleOption[]
}>()

const emit = defineEmits(['change-option'])

const selectedRoleValue = ref(props.selectedRole)

function optionChanged(option: UserRoleOption) {
  selectedRoleValue.value = props.selectedRole // prevent last value - component value is controlled by prop.selectRole
  emit('change-option', option)
}

watch(() => props.selectedRole, (value) => {
  selectedRoleValue.value = value
})
</script>

<style lang="postcss">
[data-el='ui-user-role-select'] {
  [data-el='ui-select'] {
    min-width: 166px;
  }
}

@media screen(lg) {
  [data-el='ui-user-role-select'] {
    [data-el='ui-select'] {
      min-width: 170px;
    }
  }
}
</style>
